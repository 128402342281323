@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.page {
  min-height: 100%;
  background-color: #F3F6FF;
  padding: 0 0 48px;

  @include maxWidth($bp-mTablet) {
    padding: 0 16px 48px;
  }
}



