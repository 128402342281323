@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.header {
  margin: 0 auto;
  max-width: 1440px;
  padding: 73px 120px;
  background-color: #6C9DF5;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  background-image: url(/images/mobile-app.png);
  background-repeat: no-repeat;
  background-position: 98% 100%;
  background-size: auto 100%;

  @include maxWidth($bp-sDesktop) {
    padding: 73px 60px;
    background-size: auto 90%;
  }

  @include maxWidth(1150) {
    background-size: auto 80%;
    background-position: 102% 100%;
  }

  @include maxWidth($bp-tablet) {
    background-size: auto 75%;
    padding: 52px 40px 48px;

  }

  @include maxWidth(950) {
    background-size: auto 70%;
  }

  @include maxWidth(850) {
    background-size: auto 60%;
    background-position: 115% 103%;
  }

  @include maxWidth($bp-mTablet) {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  @include maxWidth(750) {
    background-size: auto 55%;
  }

  @include maxWidth(720) {
    background-size: auto 45%;
  }

  @include maxWidth($bp-mobile) {
    padding: 24px 16px 56px;
    background-size: auto 43%;
    background-position: 124% 103%;
  }

  @include maxWidth(400) {
    background-position: 140% 103%;
  }

  @include maxWidth(380) {
    padding: 24px 16px 9px;
    background-size: auto 35%;
    background-position: 115% 103%;
  }

  @include maxWidth(360) {
    background-size: auto 30%;
    background-position: 125% 103%;
  }

  @include maxWidth(330) {
    background-size: auto 28%;
    background-position: 125% 100%;
  }

  &__title {
    color: $white;
    font-family: $font-main;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    max-width: 489px;

    @include maxWidth($bp-lMobile) {
      font-size: 24px;
      line-height: 28.8px;
      max-width: 305px;
    }
  }

  &__subtitle {
    color: $white;
    font-family: $font-main;
    font-weight: 500;
    font-size: 18px;
    line-height: 21.6px;
    max-width: 440px;
    margin-top: 16px;

    @include maxWidth($bp-mTablet) {
      max-width: 345px;
      margin-top: 24px;
    }

    @include maxWidth($bp-lMobile) {
      font-size: 16px;
      line-height: 23px;
      max-width: 305px;
      margin-top: 12px;
    }

    @include maxWidth(380) {
      font-size: 14px;
      line-height: 20.16px;
      max-width: 256px;
    }
  }

  &__mobileApps {
    display: flex;
    gap: 8px;
    margin-top: 32px;

    @include maxWidth($bp-mTablet) {
      margin-top: 24px;
    }

    @include maxWidth($bp-lMobile) {
      flex-direction: column;
    }

    a {
      background-color: $white;
      border-radius: 14px;
      width: 152px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.7;
      }

      @include maxWidth($bp-mobile) {
        width: 138px;
        height: 28px;
        padding: 6px 34px;
      }
    }
  }
}