@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.footer {
  padding: 0 120px;

  @include maxWidth($bp-sDesktop) {
    padding: 0 60px;
  }

  @include maxWidth($bp-mTablet) {
    padding: 0;
  }

  &__content {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 32px;
    border-top: 1px solid #0000001A;
  }

  a {
    font-family: $font-secondary;
    color: $gray-8;
    font-size: 11px;
    font-weight: 600;
    line-height: 15.84px;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
}