@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.notFound {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 25px;
  align-content: center;
  flex-grow: 1;
  text-align: center;
}

.notFound_Image {
  display: flex;
  justify-content: center;
  height: 50px;

  img {
    width: auto;
    height: 100%;
  }
}

.section {
  min-height: 100%;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex-grow: 1;

  --title-mb: 8px;
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.header {
  padding: 16px 0;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid var(--clr-bg-border);

  & > * {
    margin: 0 !important;
  }
}

.interval {
  color: $gray-4;
  font-size: 13px;
  font-weight: 700;
  line-height: 136%;
}

.content {
  padding: 22px 0 24px;
  border-bottom: 1px solid var(--clr-bg-border);

  &__paragraph {
    color: $gray-4;
  }

  &__title {
    color: $gray-3;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.button {
  --button-width: 100%;
  font-family: $font-main;
  font-weight: 800;
  font-size: 15px;
  line-height: 144%;
  text-align: center;
}

.price {
  margin-bottom: 6px;
  font-weight: bold;
  font-size: 42px;
  text-align: center;
}

.data {
  font-size: 14px;
  font-weight: 500;
}

.data_Item {
  display: grid;
  grid-template-columns: minmax(0, 1.2fr) minmax(0, 1fr);
  grid-gap: 10px;
  justify-content: space-between;
  margin-bottom: 16px;
  color: var(--clr-text-normal);

  & > *:last-child {
    color: var(--clr-text-main);
    text-align: right;
  }
}

.data_Footer {
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content;
  grid-gap: 10px;
  color: var(--clr-text-main);
}

.requisitesWrapper {
  margin-top: 32px;
  display: flex;
  align-items: center;
  gap: 24px;

  label {
    span {
      cursor: pointer;
    }
  }
}

.requisites {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: $font-secondary;
  font-weight: 600;
  font-size: 11px;
  line-height: 144%;
  color: $gray-8;
}

.email_container {
  margin-top: 16px;
}

.input {
  display: block;
  margin-top: 16px;

  &__error {
    position: relative;
  }

  input {
    height: auto;
    padding: 12px 15px;
    background: $white;
    border: 1px solid $gray;
    border-radius: 12px;
    font-family: $font-main;
    font-weight: 400;
    font-size: 15px;
    line-height: 144%;
    color: $gray-6;
  }

  &--active {
    input {
      background: $blue-2;
    }
  }
}

.carNumber {
  color: var(--color-blue);
  font-size: 15px;
  font-weight: 500;
  line-height: 144%;
}
