@use '@/assets/styles/vars/scss-variables' as *;
@use '@/assets/styles/mixins' as *;

.main {
  padding: 40px 120px 80px;

  @include maxWidth($bp-sDesktop) {
    padding: 40px 60px 80px;
  }

  @include maxWidth($bp-mTablet) {
    padding: 40px 0 80px;
  }

  @include maxWidth($bp-mobile) {
    padding: 32px 0 80px;
  }

  &__content {
    margin: 0 auto;
    padding: 32px 24px;
    background-color: $white;
    max-width: 1200px;
    border-radius: 32px;
    box-shadow: 0 1px 20px 0 rgba(56, 121, 240, 0.13);

    @include maxWidth($bp-mTablet) {
      border-radius: 20px;
      padding: 24px 14px;
    }

    @include maxWidth($bp-mobile) {
      padding: 24px 16px;
    }

    @include maxWidth($bp-sMobile) {
      padding: 24px 8px;
    }
  }

  &__areaName {
    color: $gray-2;
    font-family: $font-main;
    font-weight: 700;
    font-size: 32px;
    line-height: 46px;
    text-align: center;

    @include maxWidth($bp-mTablet) {
      font-size: 28px;
      line-height: 40.3px;
    }

    @include maxWidth($bp-mobile) {
      font-size: 18px;
      line-height: 25.9px;
    }

    @include maxWidth($bp-sMobile) {
      font-size: 16px;
      line-height: 23px;
    }
  }

  &__address {
    margin-top: 8px;
    color: $gray-4;
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 20px;
    line-height: 23.2px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    @include maxWidth($bp-mobile) {
      font-size: 12px;
      line-height: 13.9px;
      margin-top: 4px;
    }
  }
}

.menu {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include maxWidth($bp-tablet) {
    margin-top: 22px;
  }

  @include maxWidth($bp-mobile) {
    margin-top: 19px;
    gap: 8px;
  }

  &__button {
    width: 100%;
    color: $white;
    background-color: #1E4DA1;
    min-height: 96px;
    border-radius: 24px;
    font-family: $font-main;
    font-size: 24px;
    line-height: 27.84px;
    font-weight: 700;
    padding: 0 20px;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }

    @include maxWidth($bp-lMobile) {
      border-radius: 10px;
      font-size: 18px;
      line-height: 27px;

      svg {
        width: 40px;
        height: 40px;
      }
    }

    @include maxWidth($bp-mobile) {
      font-size: 14px;
      line-height: 20.16px;
      font-weight: 800;
      min-height: 68px;

      svg {
        width: 36px;
        height: 36px;
      }
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;

      @include maxWidth($bp-mobile) {
        flex-direction: column;
        gap: 8px;
        padding: 16px 0;
      }
    }

    span {
      @include maxWidth($bp-tablet) {
        max-width: 150px;
      }

      @include maxWidth($bp-lMobile) {
        max-width: 110px;
      }
    }

    &_accent {
      background-color: $blue;

      div {
        @include maxWidth($bp-mobile) {
          flex-direction: row;
          padding: 0;
        }
      }
    }

    &_without_line_break {
      div {
        @include maxWidth($bp-mobile) {
          flex-direction: row;
          padding: 0;
        }
      }

      span {
        max-width: none;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    gap: 16px;

    @include maxWidth($bp-mobile) {
      gap: 8px;
    }
  }
}